export default {
  "navigation": {
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "hiw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How It Works"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])}
  },
  "buttons": {
    "cys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Your Store"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "gs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
    "git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get In Touch"])},
    "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe Now"])}
  },
  "wellcome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your free online store is just a few clicks away."])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snawnaw – Everything oriental right at your doorstep. Create a customized and full-fledged online store to grow your business and boost your sales. Get your free trial for 7 days now!"])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Arabic digital platform that allows restaurants and stores owners, and services providers to reach their potential customers through their own online store that works 24/7.Wherever you are, Snawnaw connects you with your potential customers who are interested in everything oriental. "])},
    "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe and Secured"])},
    "het": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highly expert team"])}
  },
  "hiw": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How It Works"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi,"])},
    "cb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Business"])},
    "pyd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put your Data"])},
    "eys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoy your Store"])}
  },
  "homeSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell online without limitation — no matter what you sell"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])}
  },
  "wye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write Your E-mail"])},
  "sb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "easyExprinceWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support you with all the technological tools and solutions to build your professional online store. For a better connection with your audience, you'll be asked to simply create your profile, pick a favorite template, and add your products, then track and manage your orders. Snawnaw technical support is always ready to assist you."])},
  "whoIsSanawnaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snawnaw AKA 'Swallows' is a bird that often adapts to new environments and migrates from Palestine, Lebanon, and Syria to Europe. The Arab immigrants usually referred to as (الطيور المهاجرة) the migrant birds"])},
  "features": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything for your online store is in a complete system that includes:"])},
    "feat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- A fully customizable online store for your business."])},
    "feat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- An overall ordering, booking, and payment system."])},
    "feat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Multiple sales tools, along with marketing tools and strategies."])},
    "feat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Modern technological solutions that meet your expectations. "])},
    "outer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can always review and manage your orders via your personal dashboard. More Professional Level? Add a mobile application package to ensure that you get the highest ROI and reach more of your potential customers everywhere."])}
  },
  "choseDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the online store design that suits your business. All designs and templates are customizable to be professionally published as you hope for."])},
  "pricing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose The Offer That Suits You"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book the package that works for your business and contact us for more details. A free trial for 7 days. Start now!"])},
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
    "gs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
    "fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Access"])},
    "gy30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Your 30 day Free Trial"])},
    "br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platinum"])},
    "gd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silver"])}
  }
}