<template>
  <div>
    <div class="home-container">
      <section class="row h-100 hero-content">
        <!-- <div class="col-12 d-flex d-md-none hero-illusts" v-if='smallScreens'>
        <div class="home-section-img">
          <img src='../assets/images/fckenshit.png' height="100%" width="100%"/>
        </div>
      </div> -->

        <!-- <span class="hero-text-illust">
              <div class="text-illust">
                <img src="../assets/images/48.png" height="100%" width="100%" />
              </div>
              free
            </span> -->
        <div class="col-12 col-lg-6 p-4 pb-5">
          <h2 class="hero-Text mt-5">
            {{ $t("wellcome.title") }}
          </h2>
          <p class="hero-subText my-4">
            {{ $t("wellcome.desc") }}
          </p>

          <div class="hero-btns my-4">
            <button class="btn-pri mx-2 my-3" type="button">
              {{ $t("buttons.cys") }}
            </button>
            <button class="btn-sec mx-2" type="button">
              {{ $t("buttons.git") }}
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6 wellcome-image">
          <img src="../assets/images/fckenshit.png" alt="Imagge" />
        </div>
      </section>
    </div>

    <div class="app-sizing section-spacing">
      <AboutUs />
    </div>
    <div class="app-sizing">
      <HowItWorks class="section-spacing" id="HowItWorks" />
      <div
        v-for="sec in ImageSideSections"
        :key="sec.id"
        class="section-spacing"
        id="features"
      >
        <ImageSide :sec="sec" />
      </div>
    </div>

    <div class="app-sizing section-spacing pricing-grad">
      <Pricing />
    </div>
    <!-- <div class="app-sizing section-spacing">
      <DottedMap />
    </div> -->
    <div class="app-sizing section-spacing mb-1 subscribe-container">
      <Subscribe />
    </div>
  </div>
</template>

<script>
import DottedMap from "@/components/DottedMap";
import AboutUs from "@/components/AboutUs";
import HowItWorks from "@/components/HowItWorks";
import ImageSide from "@/components/ImageSide";
import Pricing from "@/components/Pricing";
import Subscribe from "@/components/Subscribe";
// @ is an alias to /src
export default {
  name: "Home",
  components: {
    DottedMap,
    AboutUs,
    HowItWorks,
    ImageSide,
    Pricing,
    Subscribe,
  },
  data() {
    return {};
  },
  computed: {
    ImageSideSections() {
      return [
        {
          id: 1,
          img: require("../assets/images/woman.png"),
          left: true,
          title: this.$t("homeSection.title"),
          subTitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu.  ut vehicula risus. Sed in lacus ullamcorper, rutrum turpis at, aliquet elit.",
          points: [
            { id: 1, text: "Unlimited Products" },
            { id: 2, text: "Unlimited Products" },
            { id: 3, text: "Unlimited Products" },
            { id: 4, text: "Unlimited Products" },
          ],
        },
        {
          id: 2,
          left: false,
          img: require("../assets/images/cartwithflag.png"),
          title: this.$t("homeSection.title"),
          subTitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu.  ut vehicula risus. Sed in lacus ullamcorper, rutrum turpis at, aliquet elit.",
          points: [
            { id: 1, text: "Unlimited Products" },
            { id: 2, text: "Unlimited Products" },
            { id: 3, text: "Unlimited Products" },
            { id: 4, text: "Unlimited Products" },
          ],
        },
        {
          id: 3,
          left: true,
          img: require("../assets/images/hand.png"),
          title: this.$t("homeSection.title"),
          subTitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu.  ut vehicula risus. Sed in lacus ullamcorper, rutrum turpis at, aliquet elit.",
          points: [
            { id: 1, text: "Unlimited Products" },
            { id: 2, text: "Unlimited Products" },
            { id: 3, text: "Unlimited Products" },
            { id: 4, text: "Unlimited Products" },
          ],
        },
        {
          id: 4,
          left: false,
          img: require("../assets/images/pointing.png"),
          title: this.$t("homeSection.title"),
          subTitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu.  ut vehicula risus. Sed in lacus ullamcorper, rutrum turpis at, aliquet elit.",
          points: [
            { id: 1, text: "Unlimited Products" },
            { id: 2, text: "Unlimited Products" },
            { id: 3, text: "Unlimited Products" },
            { id: 4, text: "Unlimited Products" },
          ],
        },
      ];
    },
  },
  created() {
    // console.log(this.largeScreens)
  },
};
</script>
