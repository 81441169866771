<template>
  <div class="language-toggler dropdown mx-1">
    <button
      :class="['btn-nav', smallScreens ? 'w-100' : '']"
      type="button"
      id="accountDropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <LanguageIcon /> {{ $t("buttons.l") }}
    </button>
    <ul
      class="dropdown-menu languages-menu"
      aria-labelledby="dropdownMenuButton1"
    >
      <li v-for="availablelang in availableLangs" :key="availablelang">
        <a
          class="dropdown-item dropdown-link"
          :class="t_ar"
          @click="changeLang(availablelang)"
        >
          <img class="mx-2" :src="LangImg(availablelang)" />

          {{ $t(availablelang) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import LanguageIcon from "../misc/LanguageIcon.vue";

export default {
  props: ["mob"],
  components: {
    LanguageIcon,
  },
  data() {
    return {
      selectedLang: "",
    };
  },
  methods: {
    currentLangImg() {
      return require(`../../assets/images/flags/${this.lang}circle.png`);
    },
    LangImg(availablelang) {
      return require(`../../assets/images/flags/${availablelang}circle.png`);
    },
    changeLang(lang) {
      console.log(this.lang);
      if (lang !== this.lang) {
        this.$i18n.locale = lang;
        let newPref = {
          lang: lang,
        };
      }
    },
  },
  computed: {
    availableLangs() {
      return this.$i18n.availableLocales;
    },
  },
};
</script>
