import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import i18n from './i18n'
import LoadScript from "vue-plugin-load-script";

import index from './helpers/index';

createApp(App).use(i18n)
  .use(mdiVue, {
    icons: mdijs
  })
  .mixin(index)
  .use(LoadScript)
  .use(store)
  .use(router)
  .mount('#app')
