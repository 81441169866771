export default {
  data() {
    return {
      // appabr: 'sj',
      appabr: 'cc',
      innerWidth: window.innerWidth,
      sortTemp: [],
    }
  },
  created() {
    // if(this.storageGet('up_cc')) {
    //   this.$i18n.locale = this.storageGet('up_cc').lang;
    // }
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth;
      // console.log(this.width)
    },
  },
  methods: {
    // sortCategories(categories) {
    //   this.sortTemp = categories.sort((a, b) => {
    //     parseInt(a.ord) - parseInt(b.ord)
    //   });
    //   this.sortTemp.forEach(el => {
    //     if(el.childs.length > 1) {
    //       el.childs.sort((a, b) => {
    //         parseInt(a.ord) - parseInt(b.ord)
    //       });
    //     }
    //   })
      
    // }
  },
  computed: {
    width() {
      if( this.innerWidth < 576 ) {
        return 'sm'
      }else if( this.innerWidth < 768 ) {
        return 'md'
      }else if( this.innerWidth < 992 ){
        return 'lg'
      }else if( this.innerWidth < 1200 && this.innerWidth >= 1400 ) {
        return 'xl'
      }else {
        return 'xxl'
      }
    },
    smallScreens() {
      return this.width == 'sm' || this.width == 'md';
    },
    largeScreens() {
      return  this.width == 'lg' || this.width == 'xl' || this.width == 'xxl';
    },
    isloggedIn() {
      return this.storageGet('u_cc');
    },    
    // sortedCategories() {
    //   return this.sortTemp;
    // },
    placeholder() {
      return require('../assets/images/logo1.png');
    },
    accountplaceholder() {
      return require('../assets/images/download.png');
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 't-end': 't-start';
    },
    t_ar_rev() {
      return this.$i18n.locale == 'ar' ? 't-start': 't-end';
    },
    fl_ar() {
      return this.$i18n.locale == 'ar' ? 'fl-row': 'fl-reverse';
    },
    fl_ar_rev() {
      return this.$i18n.locale == 'ar' ? 'fl-reverse': 'fl-row';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right': 'f-left';
    },
    f_ar_rev() {
      return this.$i18n.locale == 'ar' ? 'f-left': 'f-right';
    },
    dir_ar() {
      return this.$i18n.locale == 'ar' ? 'dir-rtl': 'dir-ltr';
    },
    lang() {
      return this.$i18n.locale;
    },
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }

}