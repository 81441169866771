<template>
  <div class="">
    <h2 class="img-side-title my-3">
      {{title}}
    </h2>
    <p class="img-side-desc my-3">
      {{desc}}
    </p>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'desc'
  ]

}
</script>