<template>
  <section class="image-side-section p-1 p-lg-4">
    <div class="row">
      <div class="col-12 col-xl-6" v-if='sec.left == true ? true : smallScreens'>
        <div class="side-img-container">
          <img :src='sec.img' width="100%" height="100%"/>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <div class="row">
          <ImgSideContent :title='sec.title' :desc='sec.subTitle' />
        </div>
        
        <div class="row">
          <div class="col-12 col-lg-6 my-3" v-for='(value, index) in 4' :key='index'>
            <div class="row align-items-center">
              <div class="col-3 col-lg-2">
                <img src='../assets/images/check.png' v-if='largeScreens' width='45' height='45' />
                <img src='../assets/images/check.png' v-if='smallScreens' width='40' height='40' />
              </div>
              <div class="col-9 col-lg-10 mt-2">
                <h4 class="aboutUs-content-header-full">
                  Highly expert team
                </h4>
                <!-- <div class="aboutUs-content-subHeader-full">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus 
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6" v-if='sec.left == true ? false : largeScreens'>
        <div class="side-img-container">
          <img :src='sec.img' width="100%" height="100%"/>
        </div>
      </div>
      
    </div>
  </section>
</template>

<script>
import ImgSideContent from '@/components/ImgSideContent';

export default {
  components: {
    ImgSideContent
  },
  props: ['sec'],
  data() {
    return {
      title: 'Sell online without limitation',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu. Nam at libero quis dui pellentesque efficitur ut ac nisi. Proin molestie volutpat neque. Aliquam erat volutpat.',
    }
  },
}

</script>