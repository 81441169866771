<template>
  <section class="p-1 p-lg-4 text-center">
    <SectionHeader :title="$t('hiw.title')" :desc="$t('hiw.desc')" />
    <div class="row">
      <div
        class="my-4 col-12 col-lg-4"
        v-for="card in worksItems"
        :key="card.id"
      >
        <WorksCard :item="card" />
      </div>
    </div>
  </section>
</template>

<script>
import WorksCard from "@/components/WorksCard";
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    WorksCard,
    SectionHeader,
  },
  computed: {
    worksItems() {
      return [
        {
          id: 1,
          icon: "cart-outline",
          title: this.$t("hiw.cb"),
          desc: "orem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu. Nam at ",
        },
        {
          id: 2,
          icon: "database",
          title: this.$t("hiw.pyd"),
          desc: "orem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu. Nam at ",
        },
        {
          id: 3,
          icon: "store",
          title: this.$t("hiw.eys"),
          desc: "orem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu. Nam at ",
        },
      ];
    },
  },
};
</script>