<template>
  <section class="image-side-section p-1 p-lg-4" id="aboutUs">
    <div class="row">
      <div class="col-12 col-xl-6" v-if="smallScreens">
        <div class="side-img-container">
          <img src="../assets/images/35.png" width="100%" height="100%" />
        </div>
        <span class="weird-illust d-none d-xl-block">
          <img src="../assets/images/34.png" width="100%" height="100%" />
        </span>
      </div>

      <div class="col-12 col-xl-6 mt-4">
        <div class="row">
          <ImgSideContent
            :title="$t('aboutUs.title')"
            :desc="$t('aboutUs.desc')"
          />
        </div>

        <div class="row my-4 mt-5">
          <div class="col-2">
            <img src="../assets/images/46.png" width="50" height="50" />
          </div>
          <div class="col-10">
            <h4 class="aboutUs-content-header">{{ $t("aboutUs.sas") }}</h4>
            <div class="aboutUs-content-subHeader">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              purus nisi, faucibus
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-2">
            <img src="../assets/images/47.png" width="50" height="50" />
          </div>
          <div class="col-10">
            <h4 class="aboutUs-content-header">{{ $t("aboutUs.het") }}</h4>
            <div class="aboutUs-content-subHeader">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              purus nisi, faucibus
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6" v-if="largeScreens">
        <div class="side-img-container">
          <img src="../assets/images/35.png" width="100%" height="100%" />
          <span class="weird-illust d-none d-xl-block">
            <img src="../assets/images/34.png" width="100%" height="100%" />
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImgSideContent from "@/components/ImgSideContent";

export default {
  components: {
    ImgSideContent,
  },
  data() {
    return {
      title: "About Us",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet viverra arcu. Nam at libero quis dui pellentesque efficitur ut ac nisi. Proin molestie volutpat neque. Aliquam erat volutpat. Aenean varius nisi sollicitudin, tempus ligula vitae, rhoncus",
    };
  },
};
</script>