export default {
  "navigation": {
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "hiw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merkmale"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer wir sind"])}
  },
  "buttons": {
    "cys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen Sie Ihren Shop"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "gs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loslegen"])},
    "git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Kontakt kommen"])},
    "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonniere jetzt"])}
  },
  "wellcome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snawnaw - Bringt den Orient zu dir nach Hause."])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestalte noch heute einen einzigartigen Online-Shop für dein Geschäft. Schnell, unkompliziert und endlos personalisierbar. Und das Beste: Unser Starter-Paket ist völlig kostenlos!"])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer wir sind"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snawnaw ist die leistungsstarke All-In-One-Plattform für alles rund um den Orient. Hast du ein orientalisches Restaurant, einen Supermarkt oder bietest du Dienstleistungen für orientalische Kunden an? Oder vielleicht willst du auch deine deutsche Hausmannskost einem neuen Publikum vorstellen? Egal woher du kommst, bei uns erreichst du genau deine Zielgruppe: Liebhaber von mittelöstlichen Produkten und Spezialitäten, die den Orient zu sich nach Hause holen wollen. Dabei sprechen wir unsere Kunden in ihrer Muttersprache an – ob auf Arabisch, Persisch, Türkisch, Englisch oder Deutsch."])},
    "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicher und geschützt"])},
    "het": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr kompetentes Team"])}
  },
  "hiw": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preisgestaltung"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Sorge, du musst kein IT-Profi sein. Egal ob Webseite oder Mobile App - um bei uns deinen Shop einzurichten benötigst du keine technische Vorerfahrung. Einfach dein Profil anlegen, Template wählen, Produkte hinzufügen und noch heute durchstarten. Und wenn du doch einmal Hilfe brauchst, steht unser technischer Support dir jederzeit zur Seite.Snawnaw hebt dein Business auf die nächste Stufe."])},
    "cb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen wählen"])},
    "pyd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre Daten ein"])},
    "eys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genießen Sie Ihren Shop"])}
  },
  "homeSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Einschränkung online verkaufen – egal, was Sie verkaufen"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])}
  },
  "wye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreiben Sie Ihre E-Mail"])},
  "sb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren"])},
  "easyExprinceWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support you with all the technological tools and solutions to build your professional online store. For a better connection with your audience, you'll be asked to simply create your profile, pick a favorite template, and add your products, then track and manage your orders. Snawnaw technical support is always ready to assist you."])},
  "whoIsSanawnaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snawnaw (gesprochen Snaunau) ist das arabische Wort für Schwalbe – ein Wandervogel, der sich oft tausende von Kilometern von seiner Heimat entfernt neuen Umgebungen anpasst. Wir finden, das beschreibt uns und unsere User auf den Punkt. Werde jetzt Teil der Community!"])},
  "features": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything for your online store is in a complete system that includes:"])},
    "feat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- A fully customizable online store for your business."])},
    "feat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- An overall ordering, booking, and payment system."])},
    "feat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Multiple sales tools, along with marketing tools and strategies."])},
    "feat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Modern technological solutions that meet your expectations. "])},
    "outer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can always review and manage your orders via your personal dashboard. More Professional Level? Add a mobile application package to ensure that you get the highest ROI and reach more of your potential customers everywhere."])}
  },
  "choseDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the online store design that suits your business. All designs and templates are customizable to be professionally published as you hope for."])},
  "pricing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie das Angebot, das zu Ihnen passt"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchen Sie das Paket, das für Ihr Unternehmen geeignet ist und kontaktieren Sie uns für weitere Informationen. Eine kostenlose Testversion für 7 Tage. Jetzt anfangen!"])},
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jährlich"])},
    "gs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loslegen"])},
    "fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voller Zugriff"])},
    "gy30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holen Sie sich Ihre 30-tägige kostenlose Testversion"])},
    "br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platin"])},
    "gd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gold"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silber"])}
  }
}