<template>
  <section class="dotted-map-container">
    <WorldMapDots />
    <div class="map-illusts" v-if='largeScreens'>
      <span class="path-1">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.618 204.126">
          <path   
            id="Path_11105" 
            data-name="Path 11105" 
            d="M738.5,580.023S790.959,460.5,867.447,414.936C928.7,378.44,993.734,378,993.734,378" 
            transform="translate(-737.126 -376.5)" 
            fill="none" 
            stroke="#707070" 
            stroke-width="2.5"
          />
        </svg>
      </span>
      <div class="path-1-card">
        <div class="map-card">
          <div class="map-card-avatar">
            <img src="../assets/images/download.png" width="100%" height="100%" />
          </div>
          <div class="map-card-text">
            best client experience
          </div>
        </div>
      </div>
    </div>

    <div class="" v-else>

    </div>
  </section>
</template>


<script>
import WorldMapDots from '@/components/misc/WorldMapDots.vue';

export default {
  components: {
    WorldMapDots,
  }

}
</script>