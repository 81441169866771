<template>
  <div :class="dir_ar">
    <AppHeader
      :openExpandNav="openExpandNav"
      :closeExpandNav="closeExpandNav"
    />
    <div class="overlay" id="overlay" @click="closeExpandNav"></div>
    <div class="">
      <router-view />
    </div>
    <AppFooter />
    <div class="backToTop" @click="goToTop" v-if="scrolling">
      <ChevroUpIcon />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/navigation/AppHeader";
import AppFooter from "@/components/navigation/AppFooter";
import ChevroUpIcon from "@/components/misc/ChevronUp.vue";
export default {
  components: {
    AppHeader,
    AppFooter,
    ChevroUpIcon,
  },
  data() {
    return {
      scrolling: false,
    };
  },

  created() {
    this.$loadScript("../../js/bootstrap.bundle.js").then((res) => {
      // console.log('bs loaded')
    });
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 250 ||
        document.documentElement.scrollTop > 250
      ) {
        this.scrolling = true;
      } else {
        this.scrolling = false;
      }
    });
  },

  methods: {
    openExpandNav() {
      let overlay = document.getElementById("overlay");
      let nav = document.getElementById("expandnav");
      if (overlay && nav) {
        nav.setAttribute("data-expanded", true);
        nav.classList.add("active");
        overlay.classList.add("active");
        this.$forceUpdate();
      }
    },
    goToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    closeExpandNav() {
      let overlay = document.getElementById("overlay");
      let nav = document.getElementById("expandnav");
      if (overlay && nav) {
        console.log("herer");
        nav.setAttribute("data-expanded", false);
        nav.classList.remove("active");
        overlay.classList.remove("active");
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/bootstrap.css";
@import "./assets/scss/all.scss";
</style>
