<template>
  <nav class="nav-container">
    <div class="appheader-mob" v-if="smallScreens">
      <div class="appheader-logo">
        <img src="../../assets/images/logo1.png" width="100%" height="100%" />
      </div>
      <div class="ind-icon" @click="toggleNavigation">
        <mdicon name="view-headline" />
        <!-- {{count}}{{cartItems.length}} -->
      </div>

      <div
        v-if="$route.name != 'Home'"
        class="ind-icon"
        @click="$router.go(-1)"
      >
        <mdicon name="chevron-left" />
      </div>

      <div class="expandnav-container" :data-expanded="false" id="expandnav">
        <ul class="expandnav-items-container">
          <li
            class="expandnav-item-container"
            v-for="(item, index) in appheaderLinks"
            :key="index"
            @click="goToSection(item)"
          >
            <div class="expandnav-link">
              {{ item.label }}
            </div>
          </li>

          <button class="btn-nav-mob" type="button">
            {{ $t("buttons.cys") }}
          </button>
          <LanguageChanger :mob="true" />
        </ul>
      </div>
    </div>

    <div class="appheader-container" v-if="largeScreens">
      <div class="appheader-logo">
        <img src="../../assets/images/logo1.png" width="100%" height="100%" />
      </div>

      <div class="appheader-links-container">
        <ul class="appheader-items-container" :class="fl_ar_rev">
          <li
            class="appheader-item-container"
            v-for="(item, index) in appheaderLinks"
            :key="index"
            @click="goToSection(item)"
          >
            <div class="appheader-link">
              {{ item.label }}
            </div>
          </li>
        </ul>
      </div>

      <div class="appheader-side d-flex">
        <div class="appheader-side-buttons">
          <button class="btn-nav" type="button">{{ $t("buttons.cys") }}</button>
        </div>
        <LanguageChanger />
      </div>
    </div>
  </nav>
</template>

<script>
import LanguageChanger from "./LanguageChanger.vue";
export default {
  components: {
    LanguageChanger,
  },
  data() {
    return {
      languagesMenu: false,
    };
  },
  props: ["closeExpandNav", "openExpandNav"],
  methods: {
    goToSection(item) {
      let element = document.getElementById(item.link);
      console.log(element);
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      if (this.smallScreens == true) {
        this.closeNavigation();
      }
    },
    toggleNavigation() {
      let nav = document.getElementById("expandnav");
      if (nav) {
        nav.getAttribute("data-expanded") == "true"
          ? this.closeExpandNav()
          : this.openExpandNav();
      }
    },
    openLanguagesMenu() {
      this.languagesMenu = !this.languagesMenu;
    },
  },
  computed: {
    appheaderLinks() {
      return [
        { id: 1, label: this.$t("navigation.a"), link: "aboutUs" },
        { id: 2, label: this.$t("navigation.hiw"), link: "HowItWorks" },
        { id: 3, label: this.$t("navigation.f"), link: "features" },
        { id: 4, label: this.$t("navigation.p"), link: "pricing" },
        { id: 5, label: this.$t("navigation.s"), link: "subscribe" },
      ];
    },
  },
};
</script>