export default {
  "navigation": {
    "s": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك الان"])},
    "p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطط الاسعار"])},
    "hiw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يعمل"])},
    "f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المميزات"])},
    "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])}
  },
  "buttons": {
    "cys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" أنشئ متجرك"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغه"])},
    "gs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابداء الان"])},
    "git": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا "])},
    "sn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك الان"])}
  },
  "wellcome": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متجرك المجاني على الإنترنت على بعد نقرات قليلة."])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سنونو، نصلك بالزبائن العرب في ألمانيا  المهتمين بأعمالك ابني متجرك الإلكتروني من خلال تطبيق سنونو؛ لعملية بيع أسهل مصممة خصيصًا لمشروعك التجاري. احجز باقة تجريبية لمدة 7 أيام مجانًا!"])}
  },
  "aboutUs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" منصة عربية رقمية تتيح لأصحاب المطاعم والمتاجر والخدمات المختلفة الوصول لعملائهم المحتملين من خلال إنشاء متجر إلكتروني خاص بهم يعمل على مدار الساعة. أينما كنت، سنونو هو طريقك المضمون الذي يصلك بعملائك المحتملين المهتمين بكل ما هو شرقي."])},
    "sas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["آمن ومضمون"])},
    "het": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فريق ذو خبرة عالية"])}
  },
  "hiw": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف يعمل السنونو"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف كيف يعمل "])},
    "pyd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع بياناتك"])},
    "eys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمتع بمتجرك"])},
    "cb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختار مجالك"])}
  },
  "homeSection": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البيع عبر الإنترنت دون قيود — بغض النظر عن ما تبيعه"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصف"])}
  },
  "wye": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتب بريدك الالكتروني"])},
  "sb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أشترك"])},
  "easyExprinceWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ندعمك بكل ما تحتاجه من حلول تكنولوجية لإنشاء متجرك الإلكتروني بدون تعقيد وتوسيع نطاق تجارتك. كل ما عليك هو أن تنشئ حساب وتقوم بإختيار تصميم يناسبك، وتبدأ في الحال في إضافة منتجاتك واستقبال ومتابعة طلبات العملاء. طاقم الدعم الفني لسنونو جاهز لمساعدتك في أي وقت."])},
  "whoIsSanawnaw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يعرف طائر السنونو بهجرته من فلسطين ولبنان وسوريا إلى أوروبا، الذي غالبًا ما يتكيف في بيئة جديدة غير موطنه. والمهاجرون العرب عادة ما يشار إليهم بالطيور المهاجرة. "])},
  "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل ما يحتاجه متجرك الإلكتروني في نظام واحد متكامل. متجر بيع قابل مصمم خصيصًا حسب تجارتك، نظام شامل للطلبات والحجز والدفع، وسائل متعددة وأدوات تسويق وحلول تكنولوجية حديثة تأتي لك بتجربة بيع مميزة. كما يمكنك دائمًا الاطلاع على جميع مبيعاتك وطلباتك عبر لوحة التحكم الشخصية الخاصة بك. تريد مستوى أفضل؟ نوفر لك خدمات تصميم وإنشاء تطبيقات الجوال لضمان الحصول على أعلى عائد لمشروعك التجاري وإمكانية الوصول لعدد أكبر من العملاء."])},
  "choseDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر من بين التصميمات المتنوعة ما يتناسب مع مشروعك التجاري. جميع التصميمات والقوالب قابلة للتعديل والتخصيص للحصول على متجر إلكتروني ذو مظهر احترافي كما تريده تمامًا."])},
  "pricing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر الباقة المناسبة لك"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احجز الحزمة التي تناسب عملك واتصل بنا للحصول على مزيد من التفاصيل. نسخة تجريبية مجانية لمدة 7 أيام. ابدأ الآن!"])},
    "y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سنوي"])},
    "gs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابداء الان"])},
    "fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول كامل"])},
    "gy30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل علي 30 يوم تجربه مجانيه"])},
    "br": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقه برونزيه"])},
    "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقه بلاتينيه"])},
    "gd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقه ذهبيه"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["باقه فضيه"])}
  }
}