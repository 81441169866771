<template>
  <section class="pricing-container p-lg-4 p-1" id="pricing">
    <div class="">
      <SectionHeader :title="$t('pricing.title')" :desc="$t('pricing.desc')" />
    </div>

    <div class="billing-choice"></div>

    <div class="pricing-cards-container">
      <div
        class="pricing-card my-4 mx-2"
        v-for="card in pricingItems"
        :key="card.id"
      >
        <h5 class="pricing-type my-4">
          {{ card.type }}
        </h5>

        <h3 class="pricing-price my-3">{{ card.price[billingTime] }} €</h3>

        <h5 class="pricing-type-billing my-4">
          {{ this.$t("pricing.y").toUpperCase() }}
        </h5>

        <div class="pricing-card-details">
          <h5 class="">
            {{ card.header }}
          </h5>
          <p class="">
            {{ card.subHeader }}
          </p>
        </div>

        <div class="pricing-btns w-100">
          <button class="btn-pri-lg w-100 my-3" type="button">
            {{ $t("pricing.gs") }}
          </button>

          <a class="btn-text my-3" type="button">
            {{ $t("pricing.gy30") }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    SectionHeader,
  },
  computed: {
    pricingItems() {
      return [
        {
          id: 1,
          type: this.$t("pricing.br"),
          price: {
            yearly: 14.99,
            monthly: 2.99,
          },
          header: this.$t("pricing.fa"),
          subHeader:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet ",
        },
        {
          id: 2,
          type: this.$t("pricing.sl"),
          price: {
            yearly: 24.99,
            monthly: 12.99,
          },
          header: this.$t("pricing.fa"),
          subHeader:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet ",
        },
        {
          id: 3,
          type: this.$t("pricing.gd"),
          price: {
            yearly: 34.99,
            monthly: 22.99,
          },
          header: this.$t("pricing.fa"),
          subHeader:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet ",
        },
        {
          id: 4,
          type: this.$t("pricing.pl"),
          price: {
            yearly: 44.99,
            monthly: 32.99,
          },
          header: this.$t("pricing.fa"),
          subHeader:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet ",
        },
      ];
    },
  },
};
</script>