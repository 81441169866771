<template>
<div class="" :class="align ? `text-${align}` : 'text-center'">
  <h2 class="section-header mt-5">
    {{title}}
  </h2>
  <p class="section-subheader my-4">
    {{desc}}
  </p>
</div>

</template>

<script>
export default {
  props: ['title', 'desc', 'align']
}
</script>