<template>
  <section class="p-lg-4 p-1" dir="ltr" id="subscribe">
    <div class="row">
      <div class="col-12 col-lg-6"></div>
      <div class="col-12 col-lg-6 pb-2 pb-lg-4">
        <div class="">
          <SectionHeader
            :align="dir_ar ? 'end' : 'start'"
            :title="$t('buttons.sn')"
            :desc="desc"
          />
        </div>
        <form @submit.prevent="submit" class="my-5">
          <div class="row align-items-center">
            <div class="col-8">
              <input
                type="email"
                class="text-input"
                :placeholder="$t('wye')"
                v-model="formData.email"
                id="email"
                required
              />
            </div>
            <button class="btn-pri-lg col-4">{{ $t("sb") }}</button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";

export default {
  components: {
    SectionHeader,
  },
  data() {
    return {
      formData: {
        email: "",
      },
      title: "Subscribe Now",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus nisi, faucibus pretium urna eu, laoreet",
    };
  },
  methods: {
    submit() {
      console.log(this.formData);
    },
  },
};
</script>