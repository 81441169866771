import { createStore } from 'vuex';
// import Axios from 'axios';
// import i18n from '../i18n';

// import auth from './auth';

export default createStore({
  state: {

  },

  mutations: {

  },

  actions: {

  },

  modules: {
    // auth,
  }
})
