<template>
  <div class="works-card-container">
    <div class="works-card-icon">
      <mdicon :name='item.icon' size="38"/>
    </div>
    <div class="works-card-title my-3">
      {{item.title}}
    </div>
    <div class="works-card-desc my-3">
      {{item.desc}}
    </div>
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>