<template>
  <footer class="appfooter">
    <div class="appfooter-mob">
     

        
    </div>

    <nav class="appfooter-container">
      <div class="appfooter-logo">
        <img src='../../assets/images/logo1.png' alt='' width="100%" height='100%' />        
      </div>

      <div class="appfooter-links">
        
      </div>

      <div class="appfooter-side">
        
      </div>

    </nav>
  </footer>
</template>